<p-toast key="inputValidation"></p-toast>
<p-messages></p-messages>
<!-- For some reason, the UI breaks without this first div. This div is a workaround -->
<div class="p-grid no-padding">
    <div class="p-col">
    </div>
</div>

<div class="p-grid p-align-center no-padding">
    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <label for="startDateFilter">Dataset Start Date*</label>
            &nbsp;
            <p-calendar 
                [(ngModel)]="startDate" 
                id="startDateFilter"
                [readonlyInput]="false" 
                [keepInvalid]="false"
                [showIcon]="true"
                [inputStyle]="{'width':'80%'}"
                [style]="{'width':'90%'}"
                pTooltip="Filter for reports which dataset date is greater or equal than this filter value"
                tooltipPosition="top"
            ></p-calendar>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <label for="endDateFilter">Dataset End Date*</label>
            &nbsp;
            <p-calendar 
                [(ngModel)]="endDate" 
                id="endDateFilter"
                [readonlyInput]="false" 
                [keepInvalid]="false"
                [showIcon]="true"
                [inputStyle]="{'width':'80%'}"
                [style]="{'width':'90%'}"
                pTooltip="Filter for reports which dataset date is less or equal than this filter value"
                tooltipPosition="top"
            ></p-calendar>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <label for="territoryFilter">Territory Filter</label>
            &nbsp;
            <p-multiSelect 
                [(ngModel)]="territoryValue" ngDefaultControl
                id="territoryFilter"
                [options]="territories" 
                [maxSelectedLabels]=1 
                [filter]=true 
                defaultLabel="All" 
                [style]="{'width':'80%'}" 
            ></p-multiSelect>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <label for="cadenceFilter">Cadence Filter</label>
            &nbsp;
            <p-multiSelect 
                [(ngModel)]="cadenceValue" ngDefaultControl
                id="cadenceFilter"
                [options]="cadences" 
                [maxSelectedLabels]=1 
                [filter]=false 
                defaultLabel="All" 
                [style]="{'width':'80%'}" 
            ></p-multiSelect>
        </div>
    </div>

    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <label for="serviceFilter">Service Filter</label>
            &nbsp;
            <p-multiSelect 
                [(ngModel)]="serviceValue" ngDefaultControl
                id="serviceFilter"
                [options]="services" 
                [maxSelectedLabels]=1 
                [filter]=false 
                defaultLabel="All" 
                [style]="{'width':'80%'}"
            ></p-multiSelect>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <label for="statusFilter">Status Filter</label>
            &nbsp;
            <p-multiSelect 
            [(ngModel)]="statusValue" ngDefaultControl
                id="statusFilter"
                [options]="statuses" 
                [maxSelectedLabels]=1 
                [filter]=false 
                defaultLabel="All" 
                [style]="{'width':'80%'}"
            ></p-multiSelect>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <label for="vendorFilter">Vendor Filter</label>
            &nbsp;
            <p-multiSelect 
                [(ngModel)]="vendorValue" ngDefaultControl
                id="vendorFilter"
                [options]="vendors" 
                [maxSelectedLabels]=1 
                [filter]=true 
                defaultLabel="All" 
                [style]="{'width':'80%'}"
            ></p-multiSelect>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
        <div class="box">
            <p-button 
                id="submitButton"
                label="Get Stats" 
                (onClick)="submit()"
                [disabled]="disableSubmit"
            ></p-button>
        </div>
    </div>
</div>

<div class="p-grid no-padding" *ngIf="showCharts">
    <div class="p-col-3">
        <p-chart 
            id="statusCountChart"
            type="doughnut" 
            [data]="statusCountChartData" 
            [options]="statusCountChartOptions" 
            width="25vw" 
            height="40vh"
        ></p-chart>
    </div>
    <div class="p-col-9">
        <p-chart 
            id="statusTimeChart"
            type="line" 
            [data]="statusTimeChartData" 
            [options]="statusTimeChartOptions" 
            width="74vw" 
            height="40vh"
        ></p-chart>
    </div>
</div>