import { GenericFilter } from './generic-filter';

export type ConfigDetailFilterType = {
    territory?: string;
    region?: string;
    cadence?: string;
    service?: string;
    vendor?: string;
    administrator?: string;
    reportType?: string;
    startDate?: Date;
    endDate?: Date;
    limit?: number;
    offset?: number;
}

export class ConfigDetailFilter extends GenericFilter<ConfigDetailFilterType> {}