export enum VendorChart {
    NIELSEN = 'Nielsen',
    BCM = 'BCM',
    OCC = 'OCC',
    BMAT = 'BMAT (chart)',
    GFK = 'GFK',
    ASSOCIATE = 'Associate',
    ARIA = 'Aria',
    HITS = 'Hits',
}
