import {Status} from 'src/app/model/enums/status.enum';
import {StatusDetail} from 'src/app/model/status-detail';

export class StatusMap {
    readonly statusMapping: Map<string, StatusDetail> = new Map();

    constructor() {
        this.init();
    }

    init() {
        const statusList = Object.values(Status);
        statusList.forEach(status => {
            let color: string;
            let backgroundColor: string;
            switch (status) {
                case Status.ON_TIME_DELIVERED: {
                    color = '#256029';
                    backgroundColor = '#C8E6C9';
                    break;
                }
                case Status.LATE_DELIVERED: {
                    color = '#8A5340';
                    backgroundColor = '#FEEDAF';
                    break;
                }
                case Status.ON_TIME_RE_DELIVERED: {
                    color = '#23547B';
                    backgroundColor = '#B3E5FC';
                    break;
                }
                case Status.LATE_RE_DELIVERED: {
                    color = '#805B36';
                    backgroundColor = '#FFD8B2';
                    break;
                }
                case Status.ON_TIME_NOT_DELIVERED: {
                    color = '#694382';
                    backgroundColor = '#ECCFFF';
                    break;
                }
                case Status.LATE_NOT_DELIVERED: {
                    color = '#C63737';
                    backgroundColor = '#FFCDD2';
                    break;
                }
                case Status.BACKFILL_PENDING: {
                    color = '#17202A';
                    backgroundColor = '#A6ACAF';
                    break;
                }
            }
            const detail: StatusDetail = {
                name: status,
                backgroundColor,
                color,
                reportCount: 0
            };
            this.statusMapping.set(status, detail);
        });
    }

    public getColor(status: string) {
        return this.statusMapping.get(status).color;
    }

    public getBackgroundColor(status: string) {
        return this.statusMapping.get(status).backgroundColor;
    }

}
