<p-messages></p-messages>
<br>
<div class="p-grid trackerPage no-padding">
    <div class="p-col p-col-nogutter">
        <div class="p-col-12 p-col-nogutter">
            <div class="p-grid p-col-nogutter no-padding">
                <div class="p-col">
                    <label for="quickLinkFilter">Quick Links</label>
                    <br>
                    <p-dropdown 
                        [(ngModel)]="quickLinkValue" ngDefaultControl
                        id="quickLinkFilter"
                        [options]="quickLinks"
                        [disabled]="loading"
                        (onChange)="applyQuickFilter()"
                    ></p-dropdown>
                </div>
                <div class="p-col">
                    <label for="startDateFilter">Dataset Start Date</label>
                    <br>
                    <p-calendar 
                        [(ngModel)]="startDate" ngDefaultControl
                        id="startDateFilter"
                        [readonlyInput]="false" 
                        [keepInvalid]="false"
                        [showIcon]="true"
                        pTooltip="Filter for reports which dataset date is greater or equal than this filter value"
                        tooltipPosition="top"
                    ></p-calendar>
                </div>
                <div class="p-col">
                    <label for="endDateFilter">Dataset End Date</label>
                    <br>
                    <p-calendar 
                        [(ngModel)]="endDate" ngDefaultControl
                        id="endDateFilter"
                        [readonlyInput]="false" 
                        [keepInvalid]="false"
                        [showIcon]="true"
                        pTooltip="Filter for reports which dataset date is less or equal than this filter value"
                        tooltipPosition="top"
                    ></p-calendar>
                </div>
                <div class="p-col">
                    <label for="submitSearchButton"></label>
                    <br>
                    <button 
                        type="button" pButton 
                        id="submitSearchButton"
                        icon="pi pi-search" 
                        iconPos="left" 
                        label="Submit Search" 
                        (click)="onClickSubmit()" 
                        style="margin-right: 0.5em;"
                        [disabled]="loading"
                    ></button>
                </div>
            </div>
            
            <div class="p-grid p-col-nogutter no-padding">
                <br>
                <div class="p-col">
                    <label for="territoryFilter">Territory</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="territoryFilter" ngDefaultControl
                        [options]="territories" 
                        [maxSelectedLabels]=1 
                        [filter]=true 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}"
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="regionFilter">Region</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="regionFilter" ngDefaultControl
                        [options]="regions" 
                        [maxSelectedLabels]=1 
                        [filter]=true 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}"
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="cadenceFilter">Cadence</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="cadenceFilter" ngDefaultControl
                        [options]="cadences" 
                        [maxSelectedLabels]=1 
                        [filter]=false 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}"
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="serviceFilter">Service</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="serviceFilter" ngDefaultControl
                        [options]="services" 
                        [maxSelectedLabels]=1 
                        [filter]=false 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}"
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="vendorFilter">Vendor</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="vendorFilter" ngDefaultControl
                        [options]="vendors" 
                        [maxSelectedLabels]=1 
                        [filter]=true 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}"
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="administratorFilter">Administrator</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="administratorFilter" ngDefaultControl
                        [options]="vendors" 
                        [maxSelectedLabels]=1 
                        [filter]=true 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}"
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="statusFilter">Status</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="statusFilter" ngDefaultControl
                        [options]="statuses" 
                        [maxSelectedLabels]=1 
                        [filter]=false 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}"
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="reportTypeFilter">Report Type</label>
                    <br>
                    <p-multiSelect
                        [(ngModel)]="reportTypeFilter" ngDefaultControl
                        [options]="reportTypes" 
                        [filter]=true 
                        defaultLabel="All" 
                        [style]="{'width':'100%'}" 
                    ></p-multiSelect>
                </div>
                <div class="p-col">
                    <label for="ticketIdFilter">Ticket ID</label>
                    <br>
                    <input
                        pInputText 
                        [(ngModel)]="ticketIdFilter" ngDefaultControl
                        type="text"
                        [style]="{'width':'100%'}" 
                        (input)="setTicketId($event.target.value)" 
                        placeholder="Use Ticket UUID"
                    >
                </div>
            </div>
        </div>
        <br>
        <div class="p-col-12 p-col-nogutter table-wrapper">
            <p-table 
                #dt
                [value]="reports"
                [lazy]="true"
                (onLazyLoad)="nextPage($event)"
                [totalRecords]="total"
                styleClass="ui-table-reports"
                [rowHover]="true"
                [paginator]="true"
                [rows]="10"
                [rowsPerPageOptions]="[10,25,50]"
                [loading]="loading"
                [globalFilterFields]="['territory','region','cadence','service','vendor','reportName','plan','status']"
                exportFilename="reportTracker"
                [columns]="selectedColumns"
            >
                <ng-template pTemplate="caption">
                    <div class="p-grid">
                        <div class="p-col-6" style="text-align:left">
                            <p-multiSelect 
                                [options]="cols" 
                                [(ngModel)]="selectedColumns" ngDefaultControl
                                optionLabel="header"
                                selectedItemsLabel="{0} columns selected" 
                                [style]="{minWidth: '200px'}" 
                                defaultLabel="Choose Columns"
                            ></p-multiSelect>
                        </div>
    
                        <div class="p-col-6" style="text-align: right">
                            <button 
                                type="button" pButton 
                                icon="pi pi-file-o" 
                                iconPos="left" 
                                label="CSV" 
                                (click)="exportCSV()" 
                                style="margin-right: 0.5em;"
                            ></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th
                            *ngFor="let col of columns"
                            [pSortableColumn]="col.field"
                            [pSortableColumnDisabled]="col.sortable ? false : true"
                        >
                            {{col.header}}
                            <p-sortIcon [field]="col.field" *ngIf="col.sortable"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="getStyles(col.field, report[col.field])">
                            <div *ngSwitchCase="'expectedReportName'">
                                <a target="_blank" *ngIf="showLink(report.status)" href="https://s3.console.aws.amazon.com/s3/object/archive-vendor-reports/{{report.deliveredReportName}}?region=us-east-1" rel="noopener noreferrer">Link</a>
                            </div>
                            <div *ngSwitchCase="'ticketId'"> 
                                <a target="_blank" *ngIf="report.ticketId" href="https://t.corp.amazon.com/{{report.ticketId}}" rel="noopener noreferrer">Ticket Link</a>
                            </div>
                            <div *ngSwitchCase="'destinationLocation'">
                                <div>
                                    <button
                                        *ngIf="!!report.destinationLocation"
                                        type="text"
                                        pButton
                                        label="View"
                                        (click)="op.toggle($event)">
                                    </button>
                                    <p-overlayPanel
                                        #op 
                                        [showCloseIcon]="true"
                                        appendTo="body"
                                    >
                                        <ul>
                                            <li *ngFor="let dl of report.destinationLocation | splitComma"> 
                                                {{dl}}
                                            </li>
                                        </ul>
                                    </p-overlayPanel>
                                </div>
                            </div>
                            <div *ngSwitchDefault>
                                {{report[col.field]}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length">
                            No records found with the current filter selection
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    *Note: All dates shown are on Pacific Standard Time (PST).
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
