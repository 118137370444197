// Angular core modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// Routing module
import { AppRoutingModule } from './app-routing.module';
// PrimeNG modules
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
// Application components
import { AppComponent } from './components/root/app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { TrackerComponent } from './components/tracker/tracker.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { TrackerZenService} from './services/tracker-zen.service';
import { FooterComponent } from './components/footer/footer.component';
import { TrackerConfigComponent } from './components/tracker-config/tracker-config.component';
// Custom pipe
import { SplitCommaStringPipe } from './utils/utils'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    TrackerComponent,
    StatisticsComponent,
    FooterComponent,
    TrackerConfigComponent,
    SplitCommaStringPipe
  ],
  imports: [
    // Angular core modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Routing module
    AppRoutingModule,
    // PrimeNG modules
    TooltipModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    FormsModule,
    MenuModule,
    TabMenuModule,
    TableModule,
    ChartModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    OverlayPanelModule
  ],
  providers: [
    TrackerZenService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
