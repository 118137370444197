<p-messages></p-messages>
<!-- For some reason, the UI breaks without this first div. This div is a workaround -->
<div class="p-grid no-padding">
    <div class="p-col">
    </div>
</div>

<div class="p-grid trackerPage no-padding table-wrapper">
    <div class="p-col p-col-nogutter">
        <div class="p-col-12 p-col-nogutter">
            <p-table 
                #dt
                [value]="configs"
                [lazy]="true"
                (onLazyLoad)="nextPage($event)"
                [totalRecords]="total"
                styleClass="ui-table-configs"
                [rowHover]="true"
                [paginator]="true"
                [rows]="25"
                [rowsPerPageOptions]="[25,50,75,100]"
                [loading]="loading"
                [globalFilterFields]="['territory','region','cadence','service','vendor','reportType','planDescription']"
            >
            <ng-template pTemplate="caption">
                <div style="text-align: right">
                    <p-button 
                        label="Reset Filters" 
                        icon="pi pi-refresh" 
                        iconPos="left" 
                        (click)="resetFilter()"
                    ></p-button>
                    &nbsp;
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input 
                        type="text" 
                        [(ngModel)]="globalFilterValue" ngDefaultControl
                        pInputText 
                        size="50" 
                        placeholder="Global Filter" 
                        (input)="dt.filterGlobal($event.target.value, 'contains')" 
                        style="width:auto"
                    >
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="territory">Territory <p-sortIcon field="territory"></p-sortIcon></th>
                    <th pSortableColumn="region">Region <p-sortIcon field="region"></p-sortIcon></th>
                    <th pSortableColumn="cadence">Cadence <p-sortIcon field="cadence"></p-sortIcon></th>
                    <th pSortableColumn="service">Service <p-sortIcon field="service"></p-sortIcon></th>
                    <th pSortableColumn="vendor">Vendor <p-sortIcon field="vendor"></p-sortIcon></th>
                    <th pSortableColumn="reportType">Report<br>Type <p-sortIcon field="reportType"></p-sortIcon></th>
                    <th pSortableColumn="planDescription">Plan <p-sortIcon field="planDescription"></p-sortIcon></th>
                    <th pSortableColumn="slaHours">SLA (days) <p-sortIcon field="slaHours"></p-sortIcon></th>
                    <th pSortableColumn="launchDate">Launch<br>Date <p-sortIcon field="launchDate"></p-sortIcon></th>
                    <th pSortableColumn="endDate">End<br>Date <p-sortIcon field="endDate"></p-sortIcon></th>
                </tr>
                <tr>
                    <th>
                        <p-multiSelect 
                            [(ngModel)]="territoryValue" ngDefaultControl
                            [options]="territories" 
                            [maxSelectedLabels]=1 
                            [filter]=true 
                            defaultLabel="Search..." 
                            [style]="{'width':'100%'}" 
                            (onChange)="dt.filter($event.value, 'territory', 'in')"
                        ></p-multiSelect>
                    </th>
                    <th>
                        <p-multiSelect 
                            [(ngModel)]="regionValue" ngDefaultControl
                            [options]="regions" 
                            [maxSelectedLabels]=1 
                            [filter]=true 
                            defaultLabel="Search..." 
                            [style]="{'width':'100%'}" 
                            (onChange)="dt.filter($event.value, 'region', 'in')"
                        ></p-multiSelect>
                    </th>
                    <th>
                        <p-multiSelect 
                            [(ngModel)]="cadenceValue" ngDefaultControl
                            [options]="cadences" 
                            [maxSelectedLabels]=1 
                            [filter]=false 
                            defaultLabel="Search..." 
                            [style]="{'width':'100%'}" 
                            (onChange)="dt.filter($event.value, 'cadence', 'in')"
                        ></p-multiSelect>
                    </th>
                    <th>
                        <p-multiSelect 
                            [(ngModel)]="serviceValue" ngDefaultControl
                            [options]="services" 
                            [maxSelectedLabels]=1 
                            [filter]=false 
                            defaultLabel="Search..." 
                            [style]="{'width':'100%'}" 
                            (onChange)="dt.filter($event.value, 'service', 'in')"
                        ></p-multiSelect>
                    </th>
                    <th>
                        <p-multiSelect 
                            [(ngModel)]="vendorValue" ngDefaultControl
                            [options]="vendors" 
                            [maxSelectedLabels]=1 
                            [filter]=true 
                            defaultLabel="Search..." 
                            [style]="{'width':'100%'}" 
                            (onChange)="dt.filter($event.value, 'vendor', 'in')"
                        ></p-multiSelect>
                    </th>
                    <th>
                        <input 
                            pInputText 
                            [(ngModel)]="reportTypeValue" ngDefaultControl
                            type="text" 
                            [style]="{'width':'100%'}" 
                            (input)="dt.filter($event.target.value, 'reportType', 'contains')" 
                            placeholder="Search..."
                        >
                    </th>
                    <th>
                        <input 
                            pInputText 
                            [(ngModel)]="planValue" ngDefaultControl
                            type="text" 
                            [style]="{'width':'100%'}" 
                            (input)="dt.filter($event.target.value, 'planDescription', 'contains')" 
                            placeholder="Search..."
                        >
                    </th>
                    <th>
                        <input 
                            pInputText 
                            [(ngModel)]="slaValue" ngDefaultControl
                            type="text" 
                            [style]="{'width':'100%'}" 
                            (input)="dt.filter($event.target.value, 'slaHours', 'contains')" 
                            placeholder="Search..."
                        >
                    </th>
                    <th>
                        <input 
                            pInputText 
                            [(ngModel)]="launchDateValue" ngDefaultControl
                            type="text" 
                            [style]="{'width':'100%'}" 
                            (input)="dt.filter($event.target.value, 'launchDate', 'contains')" 
                            placeholder="Search..."
                        >
                    </th>
                    <th>
                        <input 
                            pInputText 
                            [(ngModel)]="endDateValue" ngDefaultControl
                            type="text" 
                            [style]="{'width':'100%'}" 
                            (input)="dt.filter($event.target.value, 'endDate', 'contains')" 
                            placeholder="Search..."
                        >
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-config>
                <tr>
                    <td>{{config.territory}}</td>
                    <td>{{config.region}}</td>
                    <td>{{config.cadence}}</td>
                    <td>{{config.service}}</td>
                    <td>{{config.vendor}}</td>
                    <td>{{config.reportType}}</td>
                    <td>{{config.planDescription}}</td>
                    <td>{{config.slaHours}}</td>
                    <td>{{config.launchDate}}</td>
                    <td>{{config.endDate}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                * Note: All dates shown are on Pacific Standard Time (PST).
            </ng-template>
        </p-table>
        </div>
    </div>
</div>