import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AuthHandler} from '../../utils/auth-handler';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  items: MenuItem[];
  activeItem: MenuItem;
  userName: string;
  role: string;

  async ngOnInit() {
    this.items = [
      // {label: 'Home', icon: 'pi pi-fw pi-home', routerLink:'home'},
      {label: 'Report Tracker', icon: 'pi pi-fw pi-calendar', routerLink: 'tracker'},
      {label: 'Report Stats', icon: 'pi pi-fw pi-chart-line', routerLink: 'statistics'},
      {label: 'Tracker Config', icon: 'pi pi-fw pi-cog', routerLink: 'config'}
    ];

    this.activeItem = this.items[0];
    const authHandler = AuthHandler.Instance;
    const userToken = await authHandler.getUserToken();
    this.userName = authHandler.getUserNameFromToken(userToken);
    this.role = authHandler.getUserRoleFromToken(userToken);
  }
}
