import { Utils } from 'src/app/utils/utils';

interface DefaultFields {
    startDate?: Date;
    endDate?: Date;
    limit?: number;
    offset?: number;
}

export abstract class GenericFilter<F> {

    private filter: F & DefaultFields

    constructor(init: F & DefaultFields) {
        this.filter = init
    }

    public buildFilter(): string {
        const utils = new Utils();

        const { startDate, endDate, limit, offset } = this.filter

        const queryObject = {
            ...this.filter,
            startDate: startDate && utils.formatDate(startDate),
            endDate: endDate && utils.formatDate(endDate),
            limit: limit?.toString(),
            offset: offset?.toString()
        }

        for (const key in queryObject) if (!queryObject[key]) delete queryObject[key]

        const params = new URLSearchParams(queryObject);
        const queryString = params.toString();
        return '?' + queryString;
    }

}
