export class UIConstants {
    // PAPER View Dashboard
    public static readonly PROD_URL_PREFIX: string = 'https://reporting.music.amazon.dev';
    public static readonly BETA_URL_PREFIX: string = 'https://beta.reporting.music.amazon.dev';
    public static readonly DEV_URL_PREFIX: string = 'http://localhost:4200';
    public static readonly STAGE_PROD: string = 'Prod';
    public static readonly STAGE_BETA: string = 'Beta';
    public static readonly STAGE_DEV: string = 'Dev';

    // API Gateway
    public static readonly PROD_API_ENDPOINT: string = 'https://lvnqa4hzf3.execute-api.us-east-1.amazonaws.com/';
    public static readonly BETA_API_ENDPOINT: string = 'https://q3ucbcytli.execute-api.us-east-1.amazonaws.com/';
    public static readonly DEV_US_WEST_2_API_ENDPOINT: string = 'https://2i7ydkce63.execute-api.us-west-2.amazonaws.com/';
    public static readonly DEV_US_WEST_1_API_ENDPOINT: string = 'https://784zc92uxa.execute-api.us-west-1.amazonaws.com/';
    public static readonly API_NAME: string = 'tracker-zen-api';
    public static readonly API_VERSION: string = 'v0';
    public static readonly REPORT_API: string = '/reports';
    public static readonly CONFIG_API: string = '/configs';

    // Cognito
    public static readonly COGNITO_DOMAIN_PREFIX: string = 'paper-view-federate-oidc-';
    public static readonly COGNITO_DOMAIN_SUFFIX_US_EAST_1: string = '.auth.us-east-1.amazoncognito.com';
    public static readonly COGNITO_DOMAIN_SUFFIX_US_WEST_2: string = '.auth.us-west-2.amazoncognito.com';
    public static readonly COGNITO_DOMAIN_SUFFIX_US_WEST_1: string = '.auth.us-west-1.amazoncognito.com';

    // Regions
    public static readonly US_EAST_1: string = 'us-east-1';
    public static readonly US_WEST_2: string = 'us-west-2';
    public static readonly US_WEST_1: string = 'us-west-1';

    // Authorization Roles
    public static readonly ROLE_ADMIN: string = 'admin';
    public static readonly ROLE_USER: string = 'user';
}
