export enum VendorPublisher {
    ADDAF = 'ADDAF',
    AKM = 'AKM',
    ALLOTHERLABELS = 'General',
    AMAZON = 'Amazon',
    AMRA = 'AMRA',
    APRAAMCOS = 'APRAAMCOS',
    ARESA = 'ARESA',
    ARTISJUS = 'Artisjus',
    ASCAP = 'ASCAP',
    BACKOFFICE = 'BACKOFFICE',
    BMAT = 'BMAT',
    BMI = 'BMI',
    BUMASTEMRA = 'BUMASTEMRA',
    CDC = 'CDC',
    CMRRA = 'CMRRA',
    CSI = 'CSI',
    ECAD = 'ECAD',
    EYED = 'EYED',
    EDEM = 'EDEM',
    GMR = 'GMR',
    HDS = 'HDS',
    ICE = 'ICE',
    IMPEL = 'IMPEL',
    IPRS = 'iPRS',
    LAHARI = 'LAHARI',
    LYRICFIND = 'LyricFind',
    MLC = 'MLC',
    MRI = 'MRI',
    MUSICAUTOR = 'MUSICAUTOR',
    MUSIXMATCH = 'MusixMatch',
    OSA = 'OSA',
    SABAM = 'SABAM',
    SACEM = 'SACEM',
    SACM = 'SACM',
    SADAIC = 'SADAIC',
    SATV = 'SATV',
    SESAC = 'SESAC',
    SGAE = 'SGAE',
    SIAE = 'SIAE',
    SOCAN = 'SOCAN',
    SOLAR = 'SOLAR',
    SOZA = 'SOZA',
    SPA = 'SPA',
    SUISASESAC = 'SUISASESAC',
    SYNCPOWER = 'SyncPower',
    TEOSTO = 'TEOSTO',
    TSERIES = 'TSERIES',
    UBEM = 'UBEM',
    UMPI = 'UMPI',
    UNISON = 'UNISON',
    WCM = 'WCM',
    WORDCOLLECTIONS = 'WordCollections',
    ZEEMUSIC = 'ZEEMUSIC',
}
