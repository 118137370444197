export enum ReportType {
    ACTIVITY = "Activity",
    Alexa = "Alexa",
    PLAYLIST = "Playlist",
    USER = "User",
    CONS = "Cons",
    TOTAL = "Total",
    TRANS = "Trans",
    CUSTOMER = "Customer",
    FLASH = "Flash",
    STREAMS_AMP = "Streams-Amp",
    SALES = "Sales",
    SALES_METADATA = "Sales-Metadata",
    STREAMS = "Streams",
    SALES_ALBUM_METADATA = "Sales-Album-Metadata",
    SALES_DIGITAL = "Sales-Digital",
    SALES_PHYSICAL = "Sales-Physical",
    SALES_PHYSICAL_METADATA = "Sales-Physical-Metadata",
    SALES_TRACK_METADATA = "Sales-Track-Metadata",
    STREAMAS_ECHO = "Streams-ECHO",
    STREAMS_AMU = "Streams-AMU",
    STREAMS_PRIME_ADS = "Streams-PRIME-ADS",
    STREAMS_METADATA_TRACKS = "Streams-METADATA-TRACKS",
    STREAMS_METADATA_ALBUMS = "Streams-METADATA-ALBUMS",
    MEDIA_CONTROL = "MediaControl",
    STREAMS_AUDIO = "Streams-Audio",
    STREAMS_CAPPED_AUDIO = "Streams-Capped-Audio",
    STREAMS_VIDEO = "Streams-Video",
    STREAMS_CAPPED_VIDEO = "Streams-Capped-Video",
    PHYSICAL_SALES_BODY_US = "Physical-Sales-Body-US",
    DIGITAL_SALES_ALBUM = "Digital-Sales-Album",
    DIGITAL_SALES_BODY = "Digital-Sales-Body",
    DIGITAL_SALES_TRACK = "Digital-Sales-Track",
    PHYSICAL_SALES_BODY_CA = "Physical-Sales-Body-CA",
    MUSIC_DVD = "MusicDvd",
    PRE_ORDER = "PreOrder",
    USAGE = "Usage",
    ALBUM = "Album",
    TRACK = "Track",
    TRG = "Trg",
    XML = "XML",
    SALES_FLASH = "Sales-Flash",
    FLASH_ANNUAL_FAM = "Flash-ANNUAL-FAM",
    FLASH_ANNUAL = "Flash-ANNUAL",
    FLASH_DEVICE = "Flash-DEVICE",
    FLASH_FAM = "Flash-FAM",
    FLASH_STUDENT = "Flash-STUDENT",
    FLASH_INDIVIDUAL_90_DAY_FREE_TRIAL = "Flash-INDIVIDUAL-90-DAY-FREE-TRIAL",
    FLASH_BUNDLE_2018_ATT = "Flash-BUNDLE-2018-ATT",
    FLASH_HOLIDAY_2019_3M_99 = "Flash-HOLIDAY-2019-3M-99",
    FLASH_HOLIDAY_2019_ECHO_EXT_TRIAL = "Flash-HOLIDAY-2019-ECHO-EXT-TRIAL",
    FLASH_PRIMEDAY_2019_4M_99 = "Flash-PRIMEDAY-2019-4M-99",
    FLASH_PRIMEDAY_2019_ECHO_EXT_TRIAL = "Flash-PRIMEDAY-2019-ECHO-EXT-TRIAL",
    FLASH_FAM_4M_EXT_TRIAL = "Flash-FAM-4M-EXT-TRIAL",
    FLASH_DEVICE_2M_EXT_TRIAL = "Flash-DEVICE-2M-EXT-TRIAL",
    FLASH_DEVICE_3M_EXT_TRIAL = "Flash-DEVICE-3M-EXT-TRIAL",
    BOOKS = "Books",
    SUMMARY = "Summary",
    VENDOR = "Vendor",
    USAGE_PAID = "Usage-Paid",
    USAGE_FREE = "Usage-Free",
    A5_02 = "A5-02",
    A5_03 = "A5-03",
    A5_04 = "A5-04",
    Summary_Prime2 = "Summary-Prime2",
    Summary_Prime1 = "Summary-Prime1",
    A5_05 = "A5-05",
    Summary_ZQSOD = "Summary - ZQSOD",
    Summary_ZQSON = "Summary - ZQSON",
    Summary_P748 = "Summary - P748",
    Usage_P748 = "Usage - P748",
    Summary_PLV1 = "Summary - PLV1",
    Usage_PLV1 = "Usage - PLV1",
    Summary_ZQUD = "Summary - ZQUD",
    Summary_ZQUN = "Summary - ZQUN",
    Subscriptions = "Subscriptions",
    A5_11 = "A5-11",
    A5_12 = "A5-12",
    A5_13 = "A5-13",
    A5_14 = "A5-14",
    A5_15 = "A5-15",
    A5_16 = "A5-16",
    A5_17 = "A5-17",
    A5_27 = "A5-27",
    A5_28 = "A5-28",
    A5_43 = "A5-43",
    A5_44 = "A5-44",
    A5_45 = "A5-45",
    A5_46 = "A5-46",
    A5_47 = "A5-47",
    A5_48 = "A5-48",
    A5_49 = "A5-49",
    A5_50 = "A5-50",
    USAGE_3M_99 = "Usage-3M-99",
    USAGE_4M_99 = "Usage-4M-99",
    USAGE_FAM = "Usage-FAM",
    USAGE_ANNUAL = "Usage-ANNUAL",
    USAGE_ANNUAL_FAM = "Usage-ANNUAL-FAM",
    USAGE_STUDENT = "Usage-STUDENT",
    USAGE_DEVICE = "Usage-DEVICE",
    USAGE_INDIVIDUAL_90_DAY_FREE_TRIAL = "Usage-INDIVIDUAL-90-DAY-FREE-TRIAL",
    USAGE_FAM_4M_EXT_TRIAL = "Usage-FAM-4M-EXT-TRIAL",
    USAGE_DEVICE_2M_EXT_TRIAL = "Usage-DEVICE-2M-EXT-TRIAL ",
    USAGE_DEVICE_3M_EXT_TRIAL = "Usage-DEVICE-3M-EXT-TRIAL ",
    DISCOUNT = "Discount",
    AFF = "AFF",
    DSRF = "DSRF",
    AFF_SUMMARY = "AFF Summary",
    SCR = "SCR",
}