import { Injectable } from '@angular/core';

import { API } from 'aws-amplify';
import { UIConstants } from '../utils/ui-constants';
import { AuthHandler } from '../utils/auth-handler';

@Injectable({
  providedIn: 'root'
})
export class TrackerZenService {

  constructor() {
    console.log('TrackerZenService service connected...');
  }

  public get<ReturnType>(path: string, userToken: string): Promise<ReturnType> {
    return API.get(UIConstants.API_NAME, path, AuthHandler.getAPIHeaders(userToken))
      .then((r) => {
        try {
          return JSON.parse(r) as ReturnType
        } catch (error) {
          return r as ReturnType
        }
      })
      .catch(error => {
          console.log('API error: ' + error);
          throw error;
      });
  }

}
