import {Pipe, PipeTransform} from '@angular/core';
import {UIConstants} from './ui-constants';
import {HttpErrorResponse} from '@angular/common/http';

export class Utils {

    /**
     * Returns the API Gateway endpoint URL based on the stage that PAPER View is currently running in based on
     * checking window.location.href.
     *
     * @returns string The API Gateway endpoint URL based on the stage that PAPER View is currently running in.
     */
    public static getAPIEndpoint(): string {
        const currentBrowserLocation = String(window.location.href);
        console.log('Browser location: ' + currentBrowserLocation);
        if (currentBrowserLocation.startsWith(UIConstants.PROD_URL_PREFIX)) {
            return UIConstants.PROD_API_ENDPOINT;
        } else if (currentBrowserLocation.startsWith(UIConstants.BETA_URL_PREFIX)) {
            return UIConstants.BETA_API_ENDPOINT;
        }
        return UIConstants.DEV_US_WEST_2_API_ENDPOINT;
    }

    /**
     * Returns the stage that PAPER View is currently running in based on checking window.location.href.
     *
     * @returns string The stage that PAPER View is currently running in.
     */
    public static getStage(): string {
        const currentBrowserLocation = String(window.location.href);
        console.log('Browser location: ' + currentBrowserLocation);
        if (currentBrowserLocation.startsWith(UIConstants.PROD_URL_PREFIX)) {
            return UIConstants.STAGE_PROD;
        } else if (currentBrowserLocation.startsWith(UIConstants.BETA_URL_PREFIX)) {
            return UIConstants.STAGE_BETA;
        }
        return UIConstants.STAGE_DEV;
    }

    /**
     * Appends authorization details and stage to the API request. The token is verified by the back-end and
     * then used to confirm a user's authorization to perform an API request.
     *
     * @param path The API request path including any queryFilterParameters for the request.
     * @param stage The stage where the service is currently running (Prod, Beta, or Dev).
     * @param userToken A CognitoUser object containing the user's identity information and a verification signature.
     * @returns string The API request URL with the UI stage and user token included in the request.
     */
    public static appendAuthToRequest(path: string, stage: string, userToken: string): string {
        if (path.endsWith(UIConstants.REPORT_API) || path.endsWith(UIConstants.CONFIG_API)) {
            return path.concat('?stage=' + stage + '&userToken=' + userToken);
        } else {
            return path.concat('&stage=' + stage + '&userToken=' + userToken);
        }
    }

    /**
     * Returns the details of an HttpErrorResponse. If the status is undefined, it returns the error type and message.
     * If the status is defined, the status and status text are returned.
     *
     * @returns string The details of an HttpErrorResponse.
     */
    public static getErrorDetails(error: HttpErrorResponse): string {
        if (error.status === undefined) {
            return error.name + ': ' + error.message;
        } else {
            return error.status + ': ' + error.statusText;
        }
    }

    /**
     * Given a date, return a string with the date formatted as YYYY-MM-DD
     *
     * @param date input date to format
     * @returns string
     */
    formatDate(date: Date): string {
        return date.toISOString().split("T")[0];
    }
}

@Pipe({
    name: 'splitComma'
  })
  export class SplitCommaStringPipe implements PipeTransform {
    transform(val: string) {
      if (!!val) {
        return val.split(',');
      }
    }
  }
