import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MessageService, SelectItem } from 'primeng/api';

import { Service } from 'src/app/model/enums/service.enum';
import { Cadence } from 'src/app/model/enums/cadence.enum';
import { Status } from 'src/app/model/enums/status.enum';
import { Territory } from 'src/app/model/enums/territory.enum';
import { VendorLabelMajors } from 'src/app/model/enums/vendor-label-majors.enum';
import { VendorLabelIndies } from 'src/app/model/enums/vendor-label-indies.enum';
import { VendorPublisher } from 'src/app/model/enums/vendor-publisher.enum';
import { VendorChart } from 'src/app/model/enums/vendor-chart.enum';
import { ReportDetail } from 'src/app//model/report-detail';
import { StatusMap } from 'src/app//model/stats/status-map';
import { StatusDetail } from 'src/app/model/status-detail';
import { TrackerZenService } from 'src/app/services/tracker-zen.service';
import { StatusByDateStats } from 'src/app/model/stats/status-by-date-stats';
import { Utils } from 'src/app/utils/utils';
import { StatusDateDetail } from 'src/app/model/status-date-detail';
import { ReportDetailFilter } from 'src/app/model/filters/report-detail-filter';
import { UIConstants } from '../../utils/ui-constants';
import { AuthHandler } from '../../utils/auth-handler';
import {VendorInternal} from "../../model/enums/vendor-internal.enum";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
  providers: [MessageService]
})
export class StatisticsComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  territories: SelectItem[] = [];
  services: SelectItem[] = [];
  cadences: SelectItem[] = [];
  statuses: SelectItem[] = [];
  vendors: SelectItem[] = [];

  territoryValue: string[];
  regionValue: string[];
  administratorValue: string[];
  serviceValue: string[];
  cadenceValue: string[];
  statusValue: string[];
  vendorValue: string[];

  showCharts: boolean;
  disableSubmit = false;

  statusCountChartData: any;
  statusCountChartOptions: any;
  statusTimeChartData: any;
  statusTimeChartOptions: any;

  reports: ReportDetail[];

  readonly authSession;

  constructor(private trackerZenService: TrackerZenService, private messageService: MessageService) {
    console.log('StatisticsComponent Constructor');
    this.authSession = AuthHandler.Instance;
  }

  ngOnInit() {
    this.initializeDropDowns();
    this.initializeCharts();
  }

  initializeDropDowns() {
    Object.values(Territory).forEach(territory => {
      const entry = { label: territory, value: territory };
      this.territories.push(entry);
    });
    Object.values(Service).forEach(status => {
      const entry = { label: status, value: status };
      this.services.push(entry);
    });
    Object.values(Cadence).forEach(status => {
      const entry = { label: status, value: status };
      this.cadences.push(entry);
    });
    Object.values(Status).forEach(status => {
      const entry = { label: status, value: status };
      this.statuses.push(entry);
    });
    Object.values(VendorInternal).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorLabelMajors).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorLabelIndies).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorPublisher).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorChart).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
  }

  initializeCharts() {
    this.showCharts = false;

    this.statusCountChartData = {
      labels: [],
      datasets: [{
        data: [],
        borderColor: '#FFFFFF',
        fill: false,
        backgroundColor: [],
        hoverBackgroundColor: []
      }]
    };
    this.statusCountChartOptions = {
      title: {
        display: true,
        text: 'Counts by Status',
        fontSize: 16
      },
      legend: {
        display: true,
        position: 'bottom'
      },
      events: false,
      animation: {

        onComplete() {
          const ctx = this.chart.ctx;
          // Style settings for custom element in canvas need to be here.
          ctx.font = 'bold 12px "Arial"';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          ctx.fillStyle = '#FFF';
          this.data.datasets.forEach((dataset) => {
            for (let i = 0; i < dataset.data.length; i++) {
              // Get position on chart canvas to draw (x and y position in the canvas).
              const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
              const total = dataset._meta[Object.keys(dataset._meta)[0]].total;
              const midRadius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2;
              const startAngle = model.startAngle;
              const endAngle = model.endAngle;
              const midAngle = startAngle + (endAngle - startAngle) / 2;
              const x = midRadius * Math.cos(midAngle);
              const y = midRadius * Math.sin(midAngle);
              const percent = String(Math.round(dataset.data[i] / total * 100)) + '%';
              // Set count and percentage on the appropriate canvas position.
              if (dataset.data[i] !== 0) {
                ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                ctx.fillText(percent, model.x + x, model.y + y + 15);
              }
            }
          });
        }
      }
    };

    this.statusTimeChartData = {
      labels: [],
      datasets: []
    };
    this.statusTimeChartOptions = {
      title: {
        display: true,
        text: 'Status Over Time',
        fontSize: 16
      },
      legend: {
        display: true,
        position: 'bottom'
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Report Date'
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: '# of Reports'
          },
          ticks: {
            beginAtZero: true,
            callback(value) {if (value % 1 === 0) {return value; }}
          }
        }]
      }
    };
  }

  async submit() {
    this.messageService.clear();    // Clear previous errors when running a new query.
    const userToken = await this.authSession.getUserToken();

    // Verify that the user is authorized to perform the GET request.
    if (!this.authSession.verifyUserGETRole(userToken)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Unauthorized API Request',
        detail: 'You are unauthorized to perform this action. Contact the PAPER PAD Dev team if you need assistance.'
      });
      return;
    }

    // Verify that start date or end date for the query are provided.
    if (!this.startDate || !this.endDate) {
      this.messageService.add({
        key: 'inputValidation',
        severity: 'error',
        summary: 'Validation failed',
        detail: 'Start and End Date must be populated.'
      });
      return;
    }
    this.disableSubmit = true;
    this.showCharts = false;
    console.log('Making API call...');

    const filter = new ReportDetailFilter({
      status: this.statusValue?.join(","),
      cadence: this.cadenceValue?.join(","),
      service: this.serviceValue?.join(","),
      territory: this.territoryValue?.join(","),
      region: this.regionValue?.join(","),
      administrator: this.administratorValue?.join(","),
      vendor: this.vendorValue?.join(","),
      startDate: this.startDate && new Date(this.startDate),
      endDate: this.endDate && new Date(this.endDate),
      limit: 10000,
      offset: 0
    });

    const path = Utils.appendAuthToRequest(
      UIConstants.API_VERSION + UIConstants.REPORT_API + filter.buildFilter(),
      Utils.getStage(),
      userToken
    );

    type ResponseType = { total: number, data: ReportDetail[]};

    this.trackerZenService.get<ResponseType>(path, userToken)
        .then(
            (response) => {
              // Back-end Lambda returns null when authorization fails.
              if (response == null) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'API Error',
                  detail: 'You may not be authorized to perform this request. ' +
                      'Please try again. Contact the PAPER PAD dev team if you need assistance.'
                });
                this.reports = [];
                this.disableSubmit = false;
              } else {
                this.reports = response.data;
                console.log('... data received. Updating charts...');
                this.updateStatusCountChart();
                this.updateStatusDateChart();
                this.showCharts = true;
                this.disableSubmit = false;
              }
            },
            (error: HttpErrorResponse) => {
              this.messageService.add({
                severity: 'error',
                summary: 'API Error',
                detail: 'There was an error executing the service call: ' + Utils.getErrorDetails(error) +
                    '. Please try again.'
              });
              this.reports = [];
              this.disableSubmit = false;
            }
        );
  }

  updateStatusCountChart() {
    const statusMap = new StatusMap();
    this.reports.forEach(report => {
      const status = report.status;
      const detail: StatusDetail = statusMap.statusMapping.get(status);
      if (detail) {
        detail.reportCount = detail.reportCount + 1;
      }
    });
    const labels = Array.from(statusMap.statusMapping.keys());
    const statusDetails = [...statusMap.statusMapping.values()];
    const statusCounts = [];
    const statusColors = [];
    for (let i = 0; i < statusDetails.length; i++) {
      statusCounts[i] = statusDetails[i].reportCount;
      statusColors[i] = statusDetails[i].backgroundColor;
    }
    const newData = {
      labels,
      datasets: [{
        data: statusCounts,
        borderColor: '#FFFFFF',
        backgroundColor: statusColors,
        hoverBackgroundColor: statusColors
      }]
    };
    this.statusCountChartData = Object.assign({}, newData);
  }

  updateStatusDateChart() {
    const start = this.startDate;
    const end = this.endDate;

    const statusByDateDatasets = (new StatusByDateStats(start, end)).statusByDateDatasets;
    const statusMap = new StatusMap();
    const utils = new Utils();

    this.reports.forEach(report => {
      const rowValueDate = new Date(report.runDate.replace(/-/g, '\/'));
      const dateString = utils.formatDate(rowValueDate);
      const status = report.status;
      const detail: Map<string, number> = statusByDateDatasets.get(status);
      if (detail) {
        const nextCount = detail.get(dateString) + 1;
        detail.set(dateString, nextCount);
      }
    });

    const labels: string[] = [];
    const date = new Date(start);
    for (; date <= end; date.setDate(date.getDate() + 1)) {
      labels.push(utils.formatDate(date));
    }

    const datasets: StatusDateDetail[] = [];
    statusByDateDatasets.forEach((dateMap, status) => {
      const data = [];
      for (let i = 0; i < labels.length; i++) {
        data[i] = dateMap.get(labels[i]);
      }
      datasets.push({
        label: status,
        data,
        fill: false,
        borderColor: statusMap.getBackgroundColor(status)
      });
    });

    const newData = {
      labels,
      datasets
    };
    this.statusTimeChartData = Object.assign({}, newData);
  }

}
