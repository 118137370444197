import { GenericFilter } from './generic-filter';

export type ReportDetailFilterType = {
    status?: string;
    cadence?: string;
    service?: string;
    territory?: string;
    region?: string;
    administrator?: string;
    vendor?: string;
    reportType?: string;
    ticketId?: string;
    startDate?: Date;
    endDate?: Date;
    orderBy?: String;
    orderDirection?: "DESC" | "ASC";
    limit?: number;
    offset?: number;
}

export class ReportDetailFilter extends GenericFilter<ReportDetailFilterType> {}
