import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import 'zone.js';
import {AuthHandler} from './app/utils/auth-handler';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Handle initial user sign-in.
const authSession = AuthHandler.Instance;
authSession
    .getUserIdentity()
    .then(_ => console.log('User signed-in!'))
    .catch(error => {
        // We can't allow a user to access PV without being successfully authenticated via Midway+Federate.
        console.log('Sign-in error: ' + error);
        window.alert('There was an error signing in.\n' +
            'Contact the PAPER PAD Dev team if this issue persists.');
        window.location.reload(); // Reload to try sign-in again.
    });
