export enum Territory {
    US = 'US',
    GB = 'GB',
    DE = 'DE',
    AT = 'AT',
    FR = 'FR',
    IT = 'IT',
    ES = 'ES',
    JP = 'JP',
    CA = 'CA',
    MX = 'MX',
    BR = 'BR',
    IN = 'IN',
    AU = 'AU',
    NZ = 'NZ',
    AD = 'AD',
    AR = 'AR',
    BE = 'BE',
    BG = 'BG',
    BO = 'BO',
    CH = 'CH',
    CL = 'CL',
    CO = 'CO',
    CR = 'CR',
    CY = 'CY',
    CZ = 'CZ',
    DK = 'DK',
    DO = 'DO',
    EC = 'EC',
    EE = 'EE',
    ER = 'ER',
    FI = 'FI',
    GR = 'GR',
    GT = 'GT',
    HN = 'HN',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IS = 'IS',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MC = 'MC',
    MT = 'MT',
    NI = 'NI',
    NL = 'NL',
    NO = 'NO',
    PA = 'PA',
    PE = 'PE',
    PL = 'PL',
    PT = 'PT',
    PY = 'PY',
    RO = 'RO',
    SE = 'SE',
    SI = 'SI',
    SK = 'SK',
    SV = 'SV',
    TR = 'TR',
    UY = 'UY',
    WW = 'WW'
}
