import {Amplify, Auth} from 'aws-amplify';
import {UIConstants} from './ui-constants';
import {CognitoUser} from 'amazon-cognito-identity-js';

export class AuthHandler {
    private static authHandler: AuthHandler;

    private readonly amplifyConfig: {
        Auth: {
            userPoolWebClientId: string;
            region: string;
            userPoolId: string;
            identityPoolId: string;
            oauth: { 
                redirectSignIn: string;
                responseType: string;
                domain: string;
                redirectSignOut: string;
                scope: string[]
            };
            mandatorySignIn: boolean
        };
        API: {
            endpoints: {
                name: string;
                endpoint: string;
                region: string;
                paths: string[];
            }[];
        }
    };

    private readonly prodConfig = {
        Auth: {
            region: UIConstants.US_EAST_1,
            userPoolId: 'us-east-1_ncLNHd4YC',
            userPoolWebClientId: '5entrprcmb41hf5dthha2nj7n9',
            identityPoolId: 'us-east-1:d1a695ef-49af-4747-9f8e-4698fee56013',
            mandatorySignIn: true,
            oauth: {
                domain: UIConstants.COGNITO_DOMAIN_PREFIX + 'prod' + UIConstants.COGNITO_DOMAIN_SUFFIX_US_EAST_1,
                redirectSignIn: UIConstants.PROD_URL_PREFIX,
                redirectSignOut: UIConstants.PROD_URL_PREFIX,
                scope: ['openid'],
                responseType: 'code'
            }
        },
        API: {
            endpoints: [
                {
                    name: UIConstants.API_NAME,
                    endpoint: UIConstants.PROD_API_ENDPOINT,
                    region: UIConstants.US_EAST_1,
                    paths: ['/']
                }
            ]
        }
    };
    private readonly betaConfig = {
        Auth: {
            region: UIConstants.US_EAST_1,
            userPoolId: 'us-east-1_XgUr5jqn4',
            userPoolWebClientId: '6765oceo7mimkg2m788su57gi7',
            identityPoolId: 'us-east-1:46cb3fee-2bad-4cde-868e-4e51f30905bf',
            mandatorySignIn: true,
            oauth: {
                domain: UIConstants.COGNITO_DOMAIN_PREFIX + 'beta' + UIConstants.COGNITO_DOMAIN_SUFFIX_US_EAST_1,
                redirectSignIn: UIConstants.BETA_URL_PREFIX,
                redirectSignOut: UIConstants.BETA_URL_PREFIX,
                scope: ['openid'],
                responseType: 'code'
            }
        },
        API: {
            endpoints: [
                {
                    name: UIConstants.API_NAME,
                    endpoint: UIConstants.BETA_API_ENDPOINT,
                    region: UIConstants.US_EAST_1,
                    paths: ['/']
                }
            ]
        }
    };
    private readonly devConfigUSWest2 = {
        Auth: {
            region: UIConstants.US_WEST_2,
            userPoolId: 'us-west-2_oR8EJJjiG',
            userPoolWebClientId: '3elb86ta73diemnpc6hedqe8co',
            identityPoolId: 'us-west-2:3cd058fb-ca02-4ad0-9893-4f112f2d7a0e',
            mandatorySignIn: true,
            oauth: {
                domain: UIConstants.COGNITO_DOMAIN_PREFIX + 'test' + UIConstants.COGNITO_DOMAIN_SUFFIX_US_WEST_2,
                redirectSignIn: UIConstants.DEV_URL_PREFIX,
                redirectSignOut: UIConstants.DEV_URL_PREFIX,
                scope: ['openid'],
                responseType: 'code'
            }
        },
        API: {
            endpoints: [
                {
                    name: UIConstants.API_NAME,
                    endpoint: UIConstants.DEV_US_WEST_2_API_ENDPOINT,
                    region: UIConstants.US_WEST_2,
                    paths: ['/']
                }
            ]
        }
    };
    private readonly devConfigUSWest1 = {
        Auth: {
            region: UIConstants.US_WEST_1,
            userPoolId: 'us-west-1_r4hpsi57K',
            userPoolWebClientId: '156pdok380ducmvoaqfvubu9ak',
            identityPoolId: 'us-west-1:acfea746-948a-404d-9d02-eb21e39e952a',
            mandatorySignIn: true,
            oauth: {
                domain: UIConstants.COGNITO_DOMAIN_PREFIX + 'test' + UIConstants.COGNITO_DOMAIN_SUFFIX_US_WEST_1,
                redirectSignIn: UIConstants.DEV_URL_PREFIX,
                redirectSignOut: UIConstants.DEV_URL_PREFIX,
                scope: ['openid'],
                responseType: 'code'
            }
        },
        API: {
            endpoints: [
                {
                    name: UIConstants.API_NAME,
                    endpoint: UIConstants.DEV_US_WEST_1_API_ENDPOINT,
                    region: UIConstants.US_WEST_1,
                    paths: ['/']
                }
            ]
        }
    };

    /**
     * Returns a singleton instance of AuthHandler
     */
    public static get Instance(): AuthHandler {
        return this.authHandler || (this.authHandler = new AuthHandler());
    }

    private constructor() {
        const currentBrowserLocation = String(window.location.href);
        if (currentBrowserLocation.startsWith(UIConstants.PROD_URL_PREFIX)) {
            this.amplifyConfig = this.prodConfig;
        } else if (currentBrowserLocation.startsWith(UIConstants.BETA_URL_PREFIX)) {
            this.amplifyConfig = this.betaConfig;
        } else {
            this.amplifyConfig = this.devConfigUSWest2;
        }
        this.initialize();
    }

    /**
     * Returns an API Gateway authorization header with the given Cognito JWT string.
     *
     * @param userToken A string representation of the user's Cognito JWT.
     * @return An API Gateway authorization header with the given Cognito JWT string.
     */
    public static getAPIHeaders(userToken: string) {
        return {
            headers: {
                Authorization: userToken,
                'Content-type': 'application/json'
            }
        };
    }

    /**
     * Initializes the Amplify library with the required Cognito and API configurations for the given stage.
     */
    private initialize(): void {
        Amplify.configure(this.amplifyConfig);
    }

    /**
     * Returns the user's Cognito JWT. Prompts for sign-in if the user is not already authenticated.
     *
     * @return The user's Cognito JWT.
     */
    public async getUserIdentity(): Promise<CognitoUser> {
        try {
            return await Auth.currentAuthenticatedUser();
        } catch (error) {
            console.log('Signing in user...');
            await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        }
    }

    /**
     * Returns a string representation of the user's Cognito JWT.
     *
     * @returns A string representation of the user's Cognito JWT.
     */
    public async getUserToken(): Promise<string> {
        const userData = await this.getUserIdentity();
        return userData.getSignInUserSession().getIdToken().getJwtToken();
    }

    /**
     * Returns the user's role from their Cognito JWT.
     *
     * @param userToken A string representation of the user's Cognito JWT.
     * @return The user's role from the Cognito JWT.
     */
    public getUserRoleFromToken(userToken: string): string {
        const base64Url: string = userToken.split('.')[1];
        const decodedToken: string = JSON.parse(window.atob(base64Url));
        const role: string = decodedToken['custom:role'];

        // The first role should be the highest-access one in the Federate configuration.
        return role == null ? '' : role.split(',')[0];
    }

    /**
     * Returns the user's username from their Cognito JWT.
     *
     * @param userToken A string representation of the user's Cognito JWT.
     * @return The user's username from the Cognito JWT.
     */
    public getUserNameFromToken(userToken: string): string {
        const base64Url: string = userToken.split('.')[1];
        const decodedToken: string = JSON.parse(window.atob(base64Url));
        const username: string = decodedToken['custom:username'];

        return username == null ? '' : username;
    }

    /**
     * Checks whether the user is authorized for GET API requests based on their role.
     *
     * @param idToken A string representation of the user's Cognito JWT.
     * @return A boolean value representing whether the user is authorized for GET API requests.
     */
    public verifyUserGETRole(idToken: string): boolean {
        if (idToken == null) { return false; }

        const userName: string = this.getUserNameFromToken(idToken);
        const userRole: string = this.getUserRoleFromToken(idToken);

        // Don't trust a token when the username or role are missing. They should both always be present.
        if (userName == null || userName.length === 0) { return false; }
        if (userRole == null || userRole.length === 0) { return false; }

        return (userRole === UIConstants.ROLE_ADMIN || userRole === UIConstants.ROLE_USER);
    }

    /**
     * Checks whether the user is authorized for POST API requests based on their role.
     *
     * @param idToken A string representation of the user's Cognito JWT.
     * @return A boolean value representing whether the user is authorized for POST API requests.
     */
    public verifyUserPOSTRole(idToken: string): boolean {
        if (idToken == null) { return false; }

        const userName: string = this.getUserNameFromToken(idToken);
        const userRole: string = this.getUserRoleFromToken(idToken);

        // Don't trust a token when the username or role are missing. They should both always be present.
        if (userName == null || userName.length === 0) { return false; }
        if (userRole == null || userRole.length === 0) { return false; }

        return (userRole === UIConstants.ROLE_ADMIN);
    }

    /**
     * Checks whether the user is authorized for POST API requests based on their role.
     *
     * @param idToken A string representation of the user's Cognito JWT.
     * @return A boolean value representing whether the user is authorized for POST API requests.
     */
    public verifyUserPUTRole(idToken: string): boolean {
        if (idToken == null) { return false; }

        const userName: string = this.getUserNameFromToken(idToken);
        const userRole: string = this.getUserRoleFromToken(idToken);

        // Don't trust a token when the username or role are missing. They should both always be present.
        if (userName == null || userName.length === 0) { return false; }
        if (userRole == null || userRole.length === 0) { return false; }

        return (userRole === UIConstants.ROLE_ADMIN);
    }


    /**
     * Checks whether the user is authorized for DELETE API requests based on their role.
     *
     * @param idToken A string representation of the user's Cognito JWT.
     * @return A boolean value representing whether the user is authorized for DELETE API requests.
     */
    public verifyUserDELETERole(idToken: string): boolean {
        if (idToken == null) { return false; }

        const userName: string = this.getUserNameFromToken(idToken);
        const userRole: string = this.getUserRoleFromToken(idToken);

        // Don't trust a token when the username or role are missing. They should both always be present.
        if (userName == null || userName.length === 0) { return false; }
        if (userRole == null || userRole.length === 0) { return false; }

        return (userRole === UIConstants.ROLE_ADMIN);
    }
}
