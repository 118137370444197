import { Status } from 'src/app/model/enums/status.enum';
import { Utils } from 'src/app/utils/utils';

export class StatusByDateStats {
    readonly statusByDateDatasets: Map<string, Map<string, number>> = new Map();

    constructor(start: Date, end: Date) {
        this.initializeStatusDetailMap(start, end);
    }

    private initializeStatusDetailMap(start: Date, end: Date) {
        const utils = new Utils();
        const innerMap: Map<string, number> = new Map();
        const date = new Date(start);
        let countDays = 0;
        for (; date <= end; date.setDate(date.getDate() + 1)) {
            innerMap.set(utils.formatDate(date), 0);
            countDays++;
        }

        const statusList = Object.values(Status);
        statusList.forEach(status => {
            this.statusByDateDatasets.set(status, new Map(innerMap));
        });
    }

}
