export enum Service {
    UNLIMITED = 'Unlimited',
    PRIME = 'Prime',
    FREE = 'FreeTier',
    UNIFIED = 'Unified',
    CHARTS = 'Charts',
    SALES = 'Sales',
    PREORDER = 'PreOrder',
    MERCURY = 'Mercury',
    AMP = 'Amp'
}
