import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Table } from 'primeng/table';
import { MessageService, SelectItem } from 'primeng/api';

import { Territory } from 'src/app/model/enums/territory.enum';
import { Region } from 'src/app/model/enums/region.enum';
import { Service } from 'src/app/model/enums/service.enum';
import { Cadence } from 'src/app/model/enums/cadence.enum';
import { VendorLabelMajors } from 'src/app/model/enums/vendor-label-majors.enum';
import { VendorLabelIndies } from 'src/app/model/enums/vendor-label-indies.enum';
import { VendorPublisher } from 'src/app/model/enums/vendor-publisher.enum';
import { VendorChart } from 'src/app/model/enums/vendor-chart.enum';
import { ConfigDetail } from 'src/app/model/config-detail';
import { ConfigDetailFilter } from 'src/app/model/filters/config-detail-filter';
import { TrackerZenService } from 'src/app/services/tracker-zen.service';
import { Utils } from '../../utils/utils';
import { UIConstants } from '../../utils/ui-constants';
import { AuthHandler } from '../../utils/auth-handler';
import {VendorInternal} from "../../model/enums/vendor-internal.enum";

interface CallOptions {
  limit?: number;
  offset?: number;
};

@Component({
  selector: 'app-tracker-config',
  templateUrl: './tracker-config.component.html',
  styleUrls: ['./tracker-config.component.css'],
  providers: [MessageService]
})
export class TrackerConfigComponent implements OnInit {

  loading: boolean;
  reportTypeValue: string;
  planValue: string;
  slaValue: string;
  launchDateValue: string;
  endDateValue: string;
  globalFilterValue: string;
  territoryValue: string[];
  territories: SelectItem[] = [];
  regionValue: string[];
  regions: SelectItem[] = [];
  serviceValue: string[];
  services: SelectItem[] = [];
  cadenceValue: string[];
  cadences: SelectItem[] = [];
  vendorValue: string[];
  vendors: SelectItem[] = [];
  administratorValue: string[];

  configs: ConfigDetail[];
  total: number;

  @ViewChild('dt') private table: Table;

  readonly authHandler: AuthHandler;

  constructor(private trackerZenService: TrackerZenService, private messageService: MessageService) {
    console.log('TrackerConfigComponent Constructor');
    this.authHandler = AuthHandler.Instance;
  }

  ngOnInit(): void {
    this.initializeDropDowns();
    this.callService();
  }

  initializeDropDowns() {
    Object.values(Territory).forEach(territory => {
      const entry = { label: territory, value: territory };
      this.territories.push(entry);
    });
    Object.values(Region).forEach(region => {
      const entry = { label: region, value: region };
      this.regions.push(entry);
    });
    Object.values(Service).forEach(service => {
      const entry = { label: service, value: service };
      this.services.push(entry);
    });
    Object.values(Cadence).forEach(cadence => {
      const entry = { label: cadence, value: cadence };
      this.cadences.push(entry);
    });
    Object.values(VendorInternal).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorLabelMajors).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorLabelIndies).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorPublisher).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
    Object.values(VendorChart).forEach(vendor => {
      const entry = { label: vendor, value: vendor };
      this.vendors.push(entry);
    });
  }

  async callService(options: CallOptions = {}) {
    const { limit = 25, offset = 0 } = options

    this.messageService.clear();  // Clear previous errors when running a new query.
    const userToken: string = await this.authHandler.getUserToken();

    // Verify that the user is authorized to perform the GET request.
    if (!this.authHandler.verifyUserGETRole(userToken)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Unauthorized API Request',
        detail: 'You are unauthorized to perform this action. Contact the PAPER PAD Dev team if you need assistance.'
      });
      return;
    }

    this.loading = true;

    const filter = new ConfigDetailFilter({
      cadence: this.cadenceValue?.join(","),
      service: this.serviceValue?.join(","),
      territory: this.territoryValue?.join(","),
      region: this.regionValue?.join(","),
      administrator: this.administratorValue?.join(","),
      vendor: this.vendorValue?.join(","),
      reportType: this.reportTypeValue,
      startDate: this.launchDateValue && new Date(this.launchDateValue),
      endDate: this.endDateValue && new Date(this.endDateValue),
      limit,
      offset
    });

    const path = Utils.appendAuthToRequest(
      UIConstants.API_VERSION + UIConstants.CONFIG_API + filter.buildFilter(),
      Utils.getStage(),
      userToken
    );

    type ResponseType = { total: number, data: ConfigDetail[]}
    const response = await this.trackerZenService.get<ResponseType>(path, userToken);
    
    try {
      // Back-end Lambda returns null when authorization fails.
      if (!response) {
        this.messageService.add({
          severity: 'error',
          summary: 'API Error',
          detail: 'You may not be authorized to perform this request. ' +
              'Please try again. Contact the PAPER PAD dev team if you need assistance.'
        });
        
        this.configs = [];
        this.total = 0;
      } else {
        const { total, data } = response;
        this.configs = data?.map((config) => ({ ...config, slaHours: config.slaHours / 24 })) || [];
        this.total = total || 0;
      }
    } catch(error) {
      this.messageService.add({
        severity: 'error',
        summary: 'API Error',
        detail: 'There was an error executing the service call: ' +
          Utils.getErrorDetails(error as HttpErrorResponse) +
          '. Please try again.'
      });

      this.configs = [];
      this.total = 0;
    };

    this.loading = false;
  }

  resetFilter() {
    this.reportTypeValue = null;
    this.planValue = null;
    this.slaValue = null;
    this.launchDateValue = null;
    this.endDateValue = null;
    this.territoryValue = null;
    this.regionValue = null;
    this.serviceValue = null;
    this.cadenceValue = null;
    this.vendorValue = null;
    this.globalFilterValue = null;

    this.table.reset();
  }

  nextPage(event) {
    const { rows, first } = event
    this.callService({ limit: rows, offset: first })
  }
}
