import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title = 'home works!';
  message = 'stuff';

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(event) {
    console.log(this.message);
  }

}
