export enum Region {
    NA = 'NA',
    EU = 'EU',
    FE = 'FE',
    ROW_NA = 'ROW_NA',
    ROE_EU = 'ROE_EU',
    ROW_FE = 'ROW_FE',
    EX_US = 'ExUS',
    LATAM = 'LATAM',
    AU = 'AU',
    IN = 'IN',
    JP = 'JP',
    NZ = 'NZ',
    WW = 'WW'
}
