import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { Table } from 'primeng/table';
import { MessageService, SelectItem } from 'primeng/api';
import { FilterUtils } from 'primeng/utils';

import { Service } from 'src/app/model/enums/service.enum';
import { Cadence } from 'src/app/model/enums/cadence.enum';
import { Status } from 'src/app/model/enums/status.enum';
import { ReportType } from 'src/app/model/enums/reportType.enum';
import { StatusDetail } from 'src/app/model/status-detail';
import { ReportDetail } from 'src/app/model/report-detail';
import { StatusMap } from 'src/app/model/stats/status-map';
import { TrackerZenService } from 'src/app/services/tracker-zen.service';
import { Territory } from 'src/app/model/enums/territory.enum';
import { Region } from 'src/app/model/enums/region.enum';
import { VendorLabelMajors } from 'src/app/model/enums/vendor-label-majors.enum';
import { VendorLabelIndies } from 'src/app/model/enums/vendor-label-indies.enum';
import { VendorPublisher } from 'src/app/model/enums/vendor-publisher.enum';
import { VendorChart } from 'src/app/model/enums/vendor-chart.enum';
import { ReportDetailFilter, ReportDetailFilterType } from 'src/app/model/filters/report-detail-filter';
import { Utils } from '../../utils/utils';
import { UIConstants } from '../../utils/ui-constants';
import { AuthHandler } from '../../utils/auth-handler';
import {VendorInternal} from "../../model/enums/vendor-internal.enum";

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.css'],
  providers: [MessageService]
})
export class TrackerComponent implements OnInit {

  quickLinkValue: string;
  quickLinks: SelectItem[] = [];
  startDate: Date;
  endDate: Date;

  territories: SelectItem[] = [];
  regions: SelectItem[] = [];
  services: SelectItem[] = [];
  cadences: SelectItem[] = [];
  statuses: SelectItem[] = [];
  vendors: SelectItem[] = [];
  reportTypes: SelectItem[] = [];
  statusMapping: Map<string, StatusDetail> = new Map();

  territoryFilter: string[];
  regionFilter: string[];
  cadenceFilter: string[];
  serviceFilter: string[];
  vendorFilter: string[];
  administratorFilter: string[];
  statusFilter: string[];
  reportTypeFilter: string[];
  ticketIdFilter: string;

  reports: ReportDetail[];
  total: number;
  loading: boolean;
  cols: any[];
  rowCount: number;
  selectedColumnsValue: any[];
  filter: ReportDetailFilterType = { limit: 10, offset: 0 };

  /**
   * Prevents automatic query when user landing. This is introduced because API call likely fails
   * without any filter usage. Remove this feature when API duration improves - 2022/11/16 - hoiekim
   */
  blocked = true;

  QUICK_LINK_DAILY_MISSING = 'daily-missing';
  QUICK_LINK_PARTNER_MONTHLY_MAJORS = 'partner-monthly-majors';
  QUICK_LINK_PARTNER_MONTHLY_INDIES = 'partner-monthly-indies';
  QUICK_LINK_PUBLISHER = 'publisher-monthly';
  MAX_ROWS_IN_API_QUERY = 10000;

  readonly authSession;

  @ViewChild('dt') private table: Table;

  constructor(private trackerZenService: TrackerZenService, private messageService: MessageService) {
    console.log('TrackerComponent Constructor');
    this.authSession = AuthHandler.Instance;
  }

  ngOnInit(): void {
    FilterUtils['dateFilter'] = (value, filter): boolean => {
      return this.tableDateFilter(value, this.startDate, this.endDate);
    };
    this.initializeDropDowns();
    this.statusMapping = (new StatusMap()).statusMapping;
  }

  @Input() get selectedColumns(): any[] {
    return this.selectedColumnsValue;
  }

  set selectedColumns(val: any[]) {
    this.selectedColumnsValue = this.cols.filter(col => val.includes(col));
  }

  tableDateFilter(value: any, startDate: Date, endDate: Date): boolean {
    if (value === undefined || value === null) {
      return false;
    }
    const isStartDateNull = startDate === undefined || startDate === null;
    const isEndDateNull = endDate === undefined || endDate === null;
    const rowValueDate = new Date(value.replace(/-/g, '\/'));

    if (isStartDateNull && isEndDateNull) {
      return true;
    } else if (isStartDateNull && !isEndDateNull) {
      return rowValueDate.getTime() <= endDate.getTime();
    } else if (!isStartDateNull && isEndDateNull) {
      return rowValueDate.getTime() >= startDate.getTime();
    } else {
      return rowValueDate.getTime() >= startDate.getTime() && rowValueDate.getTime() <= endDate.getTime();
    }
  }

  initializeDropDowns() {
    this.quickLinks = [
      {label: 'None', value: null},
      {label: 'Daily Missing Reports', value: this.QUICK_LINK_DAILY_MISSING},
      {label: 'Monthly - Majors', value: this.QUICK_LINK_PARTNER_MONTHLY_MAJORS},
      {label: 'Monthly - Indies', value: this.QUICK_LINK_PARTNER_MONTHLY_INDIES},
      {label: 'Publisher', value: this.QUICK_LINK_PUBLISHER},
    ];

    const alphabetical = (a: string, b: string) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1)
    const getItem = (s: string): SelectItem => ({ label: s, value: s });

    this.territories = Object.values(Territory).sort(alphabetical).map(getItem)
    this.regions = Object.values(Region).sort(alphabetical).map(getItem)
    this.services = Object.values(Service).map(getItem)
    this.cadences = Object.values(Cadence).map(getItem)
    this.statuses = Object.values(Status).map(getItem)
    this.reportTypes = Object.values(ReportType).sort(alphabetical).map(getItem)

    const allRecipients = {
      ...VendorInternal,
      ...VendorLabelMajors,
      ...VendorLabelIndies,
      ...VendorPublisher,
      ...VendorChart
    }

    this.vendors = Object.values(allRecipients).sort(alphabetical).map(getItem)

    this.cols = [
      { field: 'runDate', header: 'Dataset Date', sortable: true },
      { field: 'territory', header: 'Territory', sortable: true },
      { field: 'region', header: 'Region', sortable: true },
      { field: 'cadence', header: 'Cadence', sortable: true },
      { field: 'service', header: 'Service', sortable: true },
      { field: 'vendor', header: 'Vendor', sortable: true },
      { field: 'reportType', header: 'Report Type', sortable: true },
      { field: 'planDescription', header: 'Plan', sortable: true },
      { field: 'status', header: 'Status', sortable: true },
      { field: 'slaDate', header: 'SLA', sortable: true },
      { field: 'deliveredDate', header: 'Delivered Date' },
      { field: 'expectedReportName', header: 'File Link', sortable: true },
      { field: 'ticketId', header: 'Ticket ID', sortable: true },
      { field: 'resolvedReason', header: 'Ticket Resolved Reason', sortable: true },
      { field: 'destinationLocation', header: 'Destination Path', sortable: true }
    ];
    this.selectedColumnsValue = this.cols.filter(this.showPartnerColumns);
  }

  showPartnerColumns(element, index, array) {
    return (element.field !== 'planDescription' && element.field !== 'recipient');
  }
  showPublisherColumns(element, index, array) {
    return (element.field !== 'cadence' && element.field !== 'reportType');
  }

  async callService() {
    this.loading = true;
    this.filter = {
      ...this.filter,
      status: this.statusFilter?.join(","),
      cadence: this.cadenceFilter?.join(","),
      service: this.serviceFilter?.join(","),
      territory: this.territoryFilter?.join(","),
      region: this.regionFilter?.join(","),
      administrator: this.administratorFilter?.join(","),
      vendor: this.vendorFilter?.join(","),
      reportType: this.reportTypeFilter?.join(","),
      ticketId: this.ticketIdFilter,
      startDate: this.startDate && new Date(this.startDate),
      endDate: this.endDate && new Date(this.endDate)
    };

    try {
      const response = await this.getData();
  
      // Back-end Lambda returns null when authorization fails.
      if (!response) {
        this.messageService.add({
          severity: 'error',
          summary: 'API Error',
          detail: 'You may not be authorized to perform this request. ' +
              'Please try again. Contact the PAPER PAD dev team if you need assistance.'
        });

        this.reports = [];
        this.total = 0;
      } else {
        const { total, data } = response;
        this.reports = data || [];
        this.total = total || 0;
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'API Error',
        detail: 'There was an error executing the service call: ' + Utils.getErrorDetails(error) +
            '. Please try again.'
      });

      this.reports = [];
      this.total = 0;
    }

    this.loading = false;
  }

  async getData() {
    this.messageService.clear();
    const userToken: string = await this.authSession.getUserToken();

    // Verify that the user is authorized to perform the GET request.
    if (!this.authSession.verifyUserGETRole(userToken)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Unauthorized API Request',
        detail: 'You are unauthorized to perform this action. Contact the PAPER PAD Dev team if you need assistance.'
      });
      return;
    };

    const params = new ReportDetailFilter(this.filter).buildFilter();
    const path = Utils.appendAuthToRequest(
      UIConstants.API_VERSION + UIConstants.REPORT_API + params,
      Utils.getStage(),
      userToken
    );

    return this.trackerZenService.get<{ total: number, data: ReportDetail[]}>(path, userToken);
  }

  getStyles(field: string, status: string) {
    if (field !== 'status') {
      return;
    }
    const detail: StatusDetail = this.statusMapping.get(status);
    let statusStyle: any;
    if (detail) {
      statusStyle = {
        backgroundColor: detail.backgroundColor,
        color: detail.color
      };
    }
    return statusStyle;
  }

  applyQuickFilter() {
    this.blocked = false;
    this.resetQueryFilters();
    const today = new Date();
    if (this.quickLinkValue === this.QUICK_LINK_DAILY_MISSING) {
      this.endDate = new Date();
      this.endDate.setDate(today.getDate() - 1);
      this.statusFilter = [Status.LATE_NOT_DELIVERED];
      this.cadenceFilter = [Cadence.DAILY];
      this.selectedColumnsValue = this.cols.filter(this.showPartnerColumns);
    } else if (this.quickLinkValue === this.QUICK_LINK_PARTNER_MONTHLY_MAJORS) {
      this.startDate = new Date();
      this.startDate.setDate(today.getDate() - 30);
      this.cadenceFilter = [Cadence.MONTHLY];
      this.vendorFilter = [];
      Object.values(VendorLabelMajors).forEach(vendor => {
        this.vendorFilter.push(vendor);
      });
      this.selectedColumnsValue = this.cols.filter(this.showPartnerColumns);
    } else if (this.quickLinkValue === this.QUICK_LINK_PARTNER_MONTHLY_INDIES) {
      this.startDate = new Date();
      this.startDate.setDate(today.getDate() - 30);
      this.cadenceFilter = [Cadence.MONTHLY];
      this.vendorFilter = [];
      Object.values(VendorLabelIndies).forEach(vendor => {
        this.vendorFilter.push(vendor);
      });
      this.selectedColumnsValue = this.cols.filter(this.showPartnerColumns);
    } else if (this.quickLinkValue === this.QUICK_LINK_PUBLISHER) {
      this.startDate = new Date();
      this.startDate.setDate(today.getDate() - 30);
      this.cadenceFilter = [Cadence.MONTHLY];
      this.vendorFilter = [];
      Object.values(VendorPublisher).forEach(vendor => {
        this.vendorFilter.push(vendor);
      });
      this.selectedColumnsValue = this.cols.filter(this.showPublisherColumns);
    } else {
      // None selected
      return;
    }
    this.callService();
  }

  resetQueryFilters() {
    this.startDate = null;
    this.endDate = null;
    this.territoryFilter = null;
    this.regionFilter = null;
    this.cadenceFilter = null;
    this.serviceFilter = null;
    this.vendorFilter = null;
    this.administratorFilter = null;
    this.statusFilter = null;
    this.reportTypeFilter = null;
  }

  showLink(status: string) {
    return status !== Status.LATE_NOT_DELIVERED && status !== Status.ON_TIME_NOT_DELIVERED;
  }

  onClickSubmit() {
    this.blocked = false;
    this.callService();
  }

  setTicketId(ticketId: string) {
    this.ticketIdFilter = ticketId;
  }

  nextPage(event) {
    if (this.blocked) return;
    const { rows, first, sortField, sortOrder } = event;
    this.filter = {
      ...this.filter,
      orderBy: sortField,
      orderDirection: sortField ? sortOrder === 1 ? "ASC" : "DESC" : "DESC",
      limit: rows,
      offset: first
    };
    this.callService();
  }

  async exportCSV() {
    const { total, MAX_ROWS_IN_API_QUERY } = this;
    if (!total) return;

    this.messageService.clear();

    if (total > MAX_ROWS_IN_API_QUERY) {
      const confirm = window.confirm(
        `Do you want to download all ${total.toLocaleString()} data?\n` +
        `Requesting large data may result in failure.`
      )
      if (!confirm) return;
    }

    this.loading = true;

    const { limit: savedLimit, offset: savedOffset } = this.filter;
    this.filter.limit = 500;
    this.filter.offset = 0;

    const data = [];

    try {
      while (data.length < total) {
        // API call should not be run in parallel because API Lambda server handles sequential requests faster.
        // This is because we have low number of Lambda instances warm as we don't have lots of traffic.
        const currentData = await this.getData();
        data.push(...currentData.data);
        this.filter.offset = data.length;
      }
    } catch (error) {
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        summary: 'API Error',
        detail: 'There was an error downloading report tracker data.\nPlease try again.'
      });
      return;
    } finally {
      this.filter.limit = savedLimit;
      this.filter.offset = savedOffset;
    }

    const headers = this.cols.map(({ header }) => header);
    const headerString = headers.join(',');

    const bodyString = data.map((row) => {
      const checkNull = (key, value) => (value === null || value === undefined ? '' : value);
      const stringify = ({ field }) => JSON.stringify(row[field], checkNull);
      return this.cols.map(stringify).join(',');
    });
    
    const csv = [ headerString, ...bodyString ].join('\r\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'Report_Tracker.csv');
    link.click();

    this.loading = false;

    this.messageService.add({
      severity: 'info',
      summary: 'Complete',
      detail: `Successfully Downloaded ${data.length.toLocaleString()} report tracker data`
    });
  }
}
